import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';
@Component({
  selector: 'app-createbranch',
  templateUrl: './createbranch.page.html',
  styleUrls: ['./createbranch.page.scss'],
})
export class CreatebranchPage implements OnInit {

  constructor(public modal:ModalController,public bento:BentoService) { }

  branch={
    name:"",
    latitude:3,
    longitude:101,
    postcodes:{},
    status:true,
    default:false,
  }

  postcodes="";


  branches=[];

  ngOnInit() {
    firebase.database().ref('branches').once('value',data=>{
      this.branches = ( data.val() ? Object.values(data.val()) : []);

      
    })
  }

  okcode(x){

    return new Promise((resolve, reject)=>{
      let holder = true;

      (this.branches.map(a=>a.postcodes)).forEach(element => {
        
        (Object.keys(element).includes(x) == true ? holder=false : null)

      });

      resolve(holder);

    })

  }


  converter(x){
    if(x){
      
      let holder={};

      ((((x.split(",").join("|")).split("\n").join("|")).split(" ").join("|")).split("|")).forEach(element => {
        if(element!=""){

          this.okcode(element).then(a=>{
            console.log(a);
            if(a==true){
              holder[element]=true;
            }
          })

          
        }
      });

      return holder;

    }else{
      return {};
    }
  }

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Branch", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        this.bento.swal_alert("Branch Created!","Created","success",2000);
        this.branch.postcodes = this.converter(this.postcodes);
        setTimeout(() => {
          firebase.database().ref('branches').push(this.branch)
        }, 100);

        this.modal.dismiss();
      }
    })
  }

}
