import { Injectable, ViewChild, ElementRef } from '@angular/core';
import * as firebase from 'firebase';
import swal from 'sweetalert'
import { ActivatedRoute } from '@angular/router';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import * as EXIF from 'exif-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastController, NavController, IonContent } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';

// INSTALLER
// ionic cordova plugin add cordova-plugin-safariviewcontroller cordova-plugin-x-socialsharing
// npm install firebase @angular/fire @ionic-native/safari-view-controller exif-js sweetalert @ionic/storage @ionic-native/social-sharing --save

// << PUT IN APP MODULE
// import { HttpClientModule } from '@angular/common/http';
// import { IonicStorageModule } from '@ionic/storage';
// IonicStorageModule.forRoot(),

@Injectable({
  providedIn: 'root'
})
export class BentoService {
  @ViewChild (IonContent,{static:false}) content:IonContent;
  @ViewChild('uploadEl', { static: false }) uploadEl: ElementRef;
  constructor(private nativeGeocoder: NativeGeocoder, private socialSharing: SocialSharing, private storage: Storage,public activatedRoute: ActivatedRoute, public safariViewController: SafariViewController, public http: HttpClient, public toastController: ToastController, public nav: NavController) { }


  //   <div class="changeButton">
  //   <label for="filessquare" class="btn"> <div style='padding:6px 12px;background-color: #FF1960;color:white;border-radius: 5px;cursor: pointer;font-size:14px;'>Change</div>
  //   </label>
  //   <input style="display: none" id="filessquare" class="inputclass" accept="image/*" type="file"
  //     (change)="fileChange($event,'this.user.photo',300)">
  // </div>
  // <canvas style="display: none;" id="canvassquare"></canvas>

  imgurheaders = new HttpHeaders({
    'Authorization': 'Client-ID 2dc0beb00bb3279'
    // f425e102d31b175576a219bc7d3ba8ad82d85364
    // CHANGE TO YOUR OWN ID
  });

  geocoder_reverse(x,y,res){


    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: res
      
  };

    return new Promise((res,rej)=>{

      
  
    this.nativeGeocoder.reverseGeocode(x, y, options)
    .then((result: NativeGeocoderResult[]) => {
      console.log(JSON.stringify(result[0]))
      res(result)
    })
    .catch((error: any) => {
      console.log(error)
      rej(error);
    });

    })


  }

  share(x){
    return new Promise((res,rej)=>{

      this.socialSharing.share(x).then(e=>{
        res(e)
      }).catch(a=>{
        rej(a)
      })

    })
  }

  copy(x) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = x;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.swal_alert("复制到剪贴板","成功复制到剪贴板！","success","3000");
}

  scrollBottom(x){
    this.content.scrollToBottom(x);
  }

  todatetimeformat(x){
    return (new Date(x).toJSON()).substring(0,16);
  }

  setstorage(name,val){
    return new Promise((resolve,reject)=>{
      this.storage.set(name, val).then((a)=>{
        console.log(name)
        console.log(val)
        console.log(a)
        resolve(a);
      }).catch((e)=>{
        console.log(e)
        reject(e);
      })
    })
    
  }

  getstorage(name){
    return new Promise((resolve,reject)=>{
      this.storage.get(name).then((a)=>{
        console.log(name)
        console.log(a)
        resolve(a);
      }).catch((e)=>{
        reject(e);
      })
    })
  }

  removestorage(name){
    return new Promise((resolve,reject)=>{
      this.storage.remove(name).then((a)=>{
        resolve(a);
      }).catch((e)=>{
        reject(e);
      })
    })
  }

  callapi(extension, body,key="https://asd.com/") {

    return new Promise((resolve, reject) => {

      this.http.post(key + extension, body).subscribe(a => {

        resolve(a);

      }, e => {
        reject(e);
      })

    })


  }

  async toast(header, msg, duration, position, color, link, rout_text, rout) {
    const toast = await this.toastController.create({
      position: position,
      header: header,
      message: msg,
      duration: duration,
      mode: 'ios',
      color: color,
      buttons: [
        {
          side: 'end',
          text: rout_text,
          handler: () => {
            if (rout == "Internal") {
              this.nav.navigateForward(link);
            } else {
              this.windowopen(link, '_system');
            }
          }
        },
      ]
    });
    toast.present();
  }

  imgur(event, maxsize) {

    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {
        let imagectype = event.target.files[0].type;
        EXIF.getData(event.target.files[0], () => {
          console.log(event.target.files[0])
          console.log(event.target.files[0].exifdata.Orientation);
          var orientation = EXIF.getTag(this, "Orientation");
          var can = document.createElement('canvas');
          var ctx = can.getContext('2d');
          var thisImage = new Image;

          var maxW = maxsize;
          var maxH = maxsize;
          thisImage.onload = (a) => {

            console.log(a)
            var iw = thisImage.width;
            var ih = thisImage.height;
            var scale = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            can.width = iwScaled;
            can.height = ihScaled;
            ctx.save();
            var width = can.width; var styleWidth = can.style.width;
            var height = can.height; var styleHeight = can.style.height;
            console.log(event.target.files[0])
            if (event.target.files[0] && event.target.files[0].exifdata.Orientation) {
              console.log(event.target.files[0].exifdata.Orientation)
              if (event.target.files[0].exifdata.Orientation > 4) {
                can.width = height; can.style.width = styleHeight;
                can.height = width; can.style.height = styleWidth;
              }
              switch (event.target.files[0].exifdata.Orientation) {
                case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
                case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
                case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
                case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
                case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
                case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
                case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
              }
            }

            ctx.drawImage(thisImage, 0, 0, iwScaled, ihScaled);
            ctx.restore();

            let imagec = can.toDataURL();

            let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
            let imgarr = imgggg.split("thisisathingtoreplace;")
            let base64img = imgarr[1]
            event.target.value = ''

            

            let body = {
              image: base64img // this is the base64img from upper part
            }
            this.http.post(' https://api.imgur.com/3/image ', body, { headers:this.imgurheaders }).subscribe(res => {
              resolve(res['data'].link)
            }, awe => {
              console.log(awe)
              reject(awe)
            })

          }
          thisImage.src = URL.createObjectURL(event.target.files[0]);
          // eval('this.'+el+'.nativeElement.value = null;')
        });
      } else {
        reject("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
      }
    })


  }

  img2base64(event,maxsize){
    

    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {
        let imagectype = event.target.files[0].type;
        EXIF.getData(event.target.files[0], () => {
          console.log(event.target.files[0])
          console.log(event.target.files[0].exifdata.Orientation);
          var orientation = EXIF.getTag(this, "Orientation");
          var can = document.createElement('canvas');
          var ctx = can.getContext('2d');
          var thisImage = new Image;

          var maxW = maxsize;
          var maxH = maxsize;
          thisImage.onload = (a) => {

            console.log(a)
            var iw = thisImage.width;
            var ih = thisImage.height;
            var scale = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            can.width = iwScaled;
            can.height = ihScaled;
            ctx.save();
            var width = can.width; var styleWidth = can.style.width;
            var height = can.height; var styleHeight = can.style.height;
            console.log(event.target.files[0])
            if (event.target.files[0] && event.target.files[0].exifdata.Orientation) {
              console.log(event.target.files[0].exifdata.Orientation)
              if (event.target.files[0].exifdata.Orientation > 4) {
                can.width = height; can.style.width = styleHeight;
                can.height = width; can.style.height = styleWidth;
              }
              switch (event.target.files[0].exifdata.Orientation) {
                case 2: ctx.translate(width, 0); ctx.scale(-1, 1); break;
                case 3: ctx.translate(width, height); ctx.rotate(Math.PI); break;
                case 4: ctx.translate(0, height); ctx.scale(1, -1); break;
                case 5: ctx.rotate(0.5 * Math.PI); ctx.scale(1, -1); break;
                case 6: ctx.rotate(0.5 * Math.PI); ctx.translate(0, -height); break;
                case 7: ctx.rotate(0.5 * Math.PI); ctx.translate(width, -height); ctx.scale(-1, 1); break;
                case 8: ctx.rotate(-0.5 * Math.PI); ctx.translate(-width, 0); break;
              }
            }

            ctx.drawImage(thisImage, 0, 0, iwScaled, ihScaled);
            ctx.restore();

            let imagec = can.toDataURL();

            resolve(imagec);

          }
          thisImage.src = URL.createObjectURL(event.target.files[0]);
          // eval('this.'+el+'.nativeElement.value = null;')
        });
      } else {
        reject("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
      }
    })
  }

  base642url(base64){
    new Promise((resolve,reject)=>{
      let imgggg = base64.replace(';base64,', "thisisathingtoreplace;")
            let imgarr = imgggg.split("thisisathingtoreplace;")
            let base64img = imgarr[1]

            

            let body = {
              image: base64img // this is the base64img from upper part
            }

            this.http.post(' https://api.imgur.com/3/image ', body, { headers:this.imgurheaders }).subscribe(res => {
              resolve(res['data'].link)
            }, awe => {
              reject(awe)
            })
    })
  }

  windowopen(link, where) {
    this.safariViewController.isAvailable()
      .then((available: boolean) => {
        if (available) {

          this.safariViewController.show({
            url: link,
          })
            .subscribe((result: any) => {
              if (result.event === 'opened') console.log('Opened');
              else if (result.event === 'loaded') console.log('Loaded');
              else if (result.event === 'closed') console.log('Closed');
            },
              (error: any) => { console.error(error); window.open(link, where); }
            );

        } else {
          window.open(link, where);
          // use fallback browser, example InAppBrowser
        }
      }
      ).catch((a) => {
        console.log(a)
        window.open(link, where);
      })
  }

  lengthof(x) {
    return (x ? x.length : 0);
  }

  sum(arr, param, init) {
    if (arr) {
      return (arr.map(a => a[param])).reduce((a, b) => a + b, init)
    }

  }

  lengthof_obj(x) {
    return (x ? Object.keys(x).length : 0);
  }

  filterer(arr, keyword, d_start, d_start_param, d_end, d_end_param, params) {
    return (arr ? arr.filter(a => {
      let holder = false
      params.forEach(element => {
        if (a[element]) {
          console.log(d_start)

          holder = (keyword != "" ? (a[element].toString().toLowerCase()).includes(keyword.toString().toLowerCase()) : true) && (d_start != d_start_param ? new Date(a[d_start_param]).getTime() >= d_start : true) && (d_end != d_end_param ? new Date(a[d_end_param]).getTime() <= d_end : true);
        }

      });
      return holder
    }

    ) : []);
  }

  dater(date, style) {

    let dd = (new Date(date).getDate() < 10 ? "0" + new Date(date).getDate().toString() : new Date(date).getDate().toString());
    let mm = ((new Date(date).getMonth() + 1) < 10 ? "0" + (new Date(date).getMonth() + 1).toString() : (new Date(date).getMonth() + 1).toString());
    let yy = new Date(date).getFullYear().toString();

    return style.replace("DD", dd).replace("MM", mm).replace("YYYY", yy)
  }

  timer(date, style) {
    let ho = "";
    let ap = "";
    let mi = "";
    if (new Date(date).getHours() == 0) {
      ho = "12";
      ap = "AM";
      mi = new Date(date).getMinutes().toString();
    } else {
      ho = (new Date(date).getHours() < 13 ? (new Date(date).getHours()).toString() : (new Date(date).getHours() - 12).toString());
      ap = (new Date(date).getHours() < 12 ? "AM" : "PM");
    }


    return style.replace("HO", ho).replace("MI", mi).replace("AP", ap)
  }

  datetimer(date, style) {
    let dd = (new Date(date).getDate() < 10 ? "0" + new Date(date).getDate().toString() : new Date(date).getDate().toString());
    let mm = ((new Date(date).getMonth() + 1) < 10 ? "0" + (new Date(date).getMonth() + 1).toString() : (new Date(date).getMonth() + 1).toString());
    let yy = new Date(date).getFullYear().toString();
    let ho = "";
    let ap = "";
    let mi = "";
    if (new Date(date).getHours() == 0) {
      ho = "12";
      ap = "AM";
      mi = new Date(date).getMinutes().toString();
    } else {
      ho = (new Date(date).getHours() < 13 ? (new Date(date).getHours()).toString() : (new Date(date).getHours() - 12).toString());
      ap = (new Date(date).getHours() < 12 ? "AM" : "PM");
    }

    return style.replace("DD", dd).replace("MM", mm).replace("YYYY", yy).replace("HO", ho).replace("MI", mi).replace("AP", ap)
  }

  emailValidator(email) {
    if (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return false;
    }
  }

  pleasewait(title, text) {

    swal({
      buttons: [false],
      title: title,
      text: text,
      closeOnClickOutside: false,
      closeOnEsc: false,
    });

  }

  

  // <input #uploadEl style="display: none" id="files" class="inputclass" type="file" accept="image/*,application/pdf"(change)="fileChange($event)">
  // <canvas style="display: none;" id="canvas"></canvas>

  uploadfile(ev) {

    return new Promise((resolve, reject) => {

      var reader = new FileReader();
      var fileByteArray = [];
      if (ev) {
        this.pleasewait("Loading", "Pease Wait")
      }
      console.log(ev.target.files)
      reader.readAsArrayBuffer(ev.target.files[0]);
      reader.onloadend = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          var arrayBuffer: any = evt.target.result,

            array = new Uint8Array(arrayBuffer);

          // this.upload(arrayBuffer, ev.target.files[0].type)

          let blob = new Blob([arrayBuffer], { type: ev.target.files[0].type });



          let storage = firebase.storage().ref('Documents/' + new Date().getTime)
          storage.put(blob).then(d => {
            storage.getDownloadURL().then(snapshot => {
              swal.close()
              resolve(snapshot);
            })
            // console.log(d)
            // console.log(   Object.values(storage.getDownloadURL())[2]['D']['i']   )

          }).catch(e => {
            alert('60' + JSON.stringify(e));
          })

        }
        else {
          swal.close()
          reject("SOMETHING WONG")
        }
      }
    })

  }

  swalclose(){
    swal.close();
  }


  uploadFileToArray(ev){
    return new Promise((resolve, reject) => {

      var reader = new FileReader();
      var fileByteArray = [];
      if (ev) {
        this.pleasewait("Loading", "Pease Wait")
      }
      console.log(ev.target.files)
      reader.readAsArrayBuffer(ev.target.files[0]);
      reader.onloadend = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          var arrayBuffer: any = evt.target.result,

           array = new Uint8Array(arrayBuffer);

          // this.upload(arrayBuffer, ev.target.files[0].type)
          let obj={
            arrayBuffer:arrayBuffer,
            type: ev.target.files[0].type
          }
          resolve(arrayBuffer);

        }
        else {
          swal.close()
          reject("SOMETHING WONG")
        }
      }
    })
  }

  uploadToCloudtoUrl(arrayBuffer,type){
    return new Promise((resolve, reject) => {
      let blob = new Blob([arrayBuffer], { type: type });



      let storage = firebase.storage().ref('Documents/' + new Date().getTime)
      storage.put(blob).then(d => {
        storage.getDownloadURL().then(snapshot => {
          swal.close()
          resolve(snapshot);
        })
        // console.log(d)
        // console.log(   Object.values(storage.getDownloadURL())[2]['D']['i']   )
  
      }).catch(e => {
        alert('60' + JSON.stringify(e));
        reject(e)
      })

    })

    
  }
  
  swal_button(title, text, icon, buttons) {
    // buttons={
    //   namer:{
    //     name:"",
    //     value:"",
    //   },
    // }

    return new Promise((resolve, reject) => {
      swal({
        title: title,
        text: text,
        icon: icon,
        buttons: buttons,
        // dangerMode: true,
      })
        .then((value) => {
          if (value) {
            resolve(value);
          } else {
            reject("NO INPUT")
          }
        });
    })


  }

  swal_alert(title, text, icon, duration) {
    swal({
      title: title,
      text: text,
      icon: icon,
      timer: duration,
    });
  }

  swal_input(title, text, placeholder, type) {

    return new Promise((resolve, reject) => {


      swal({
        title: title,
        text: text,
        content: {
          element: "input",
          attributes: {
            placeholder: placeholder,
            type: type,
          },
        },

      })

        .then((value) => {
          if (value) {
            resolve(value);
          } else {
            reject("NO INPUT LOL")
          }

        });
    })


  }

  countpage(arr, step) {
    if (arr) {
      return Math.ceil(arr.length / step);
    } else {
      return 1;
    }
  }

  pager(arr, step, infinite, page) {

    // return new Promise((resolve, reject)=>{


    //   resolve('')
    // })

    if (arr) {

      if (arr.length > step * page) {

        return (infinite == true ? arr.filter((a, index) => index >= 0 && index < step * page) : arr.filter((a, index) => index >= step * (page - 1) && index < step * page));

      } else {
        return (infinite == true ? arr : arr.filter((a, index) => ((arr.length % step) == 0 ? index >= arr.length - step : index >= arr.length - (arr.length % step))));
      }

    }

  }

  numbertodigit(num,digit){
    return Math.floor(num) + Math.floor((num - Math.floor(num))*((10^digit)))/(10^digit)
  }

  convertNumberToWords(s) {

    var th = ['', 'thousand', 'million', 'billion', 'trillion'];
    var dg = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var tn = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tw = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    s = s.toString();
    s = s.replace(/[\, ]/g, '');
    if (s != parseFloat(s)) return 'not a number';
    var x = s.indexOf('.');
    if (x == -1)
      x = s.length;
    if (x > 15)
      return 'too big';
    var n = s.split('');
    var str = '';
    var sk = 0;
    for (var i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == '1') {
          str += tn[Number(n[i + 1])] + ' ';
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + ' ';
          sk = 1;
        }
      } else if (n[i] != 0) { // 0235
        str += dg[n[i]] + ' ';
        if ((x - i) % 3 == 0) str += 'hundred ';
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk)
          str += th[(x - i - 1) / 3] + ' ';
        sk = 0;
      }
    }

    if (x != s.length) {
      var y = s.length;
      str += 'point ';
      for (let i = x + 1; i < y; i++)
        str += dg[n[i]] + ' ';
    }
    return str.replace(/\s+/g, ' ');
  }

}
