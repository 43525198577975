import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { FIREBASE_CONFIG } from './app.firebase.config';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { PathLocationStrategy } from '@angular/common';
import { SafariViewController } from '@ionic-native/safari-view-controller/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';
import { CreatebroadcastPageModule } from './createbroadcast/createbroadcast.module';
import { EditbroadcastPageModule } from './editbroadcast/editbroadcast.module';
import { CreatecategoryPageModule } from './createcategory/createcategory.module';
import { EditcategoryPageModule } from './editcategory/editcategory.module';
import { CreatebranchPageModule } from './createbranch/createbranch.module';
import { EditbranchPageModule } from './editbranch/editbranch.module';
import { CreateagentPageModule } from './createagent/createagent.module';
import { EditagentPageModule } from './editagent/editagent.module';
import { CreateproductPageModule } from './createproduct/createproduct.module';
import { EditproductPageModule } from './editproduct/editproduct.module';
import { DatePipe} from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
     AngularFireModule.initializeApp(FIREBASE_CONFIG),
     IonicStorageModule.forRoot(),
     HttpClientModule,
    AngularFireAuthModule,
  CreatebroadcastPageModule,
  EditbroadcastPageModule,
    CreatecategoryPageModule,
    EditcategoryPageModule,
    CreatebranchPageModule,
    EditbranchPageModule,
    CreateagentPageModule,
    EditagentPageModule,
    CreateproductPageModule,
    EditproductPageModule,
],
  providers: [
    NativeGeocoder,
    SocialSharing,
    Geolocation,
    InAppBrowser,
    DatePipe,
    SafariViewController,
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
