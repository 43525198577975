import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-createproduct',
  templateUrl: './createproduct.page.html',
  styleUrls: ['./createproduct.page.scss'],
})
export class CreateproductPage implements OnInit {

  constructor(public modal: ModalController, public bento: BentoService, public sanitizer:DomSanitizer) { }

  product = {
    categories: [],
    description: "",
    id: "",
    sku: "",
    name: "",
    photo: [{
      type:"photo",
      url:"https://i.pinimg.com/originals/f9/ec/70/f9ec709b9cdd1d0afa28f58a30f1dfb8.gif",
      url_sanitized:null,
    }],
    price: 0,
    price_slash: 0,
    qty: 0,
    ribbon: "",
    sold: 0,
    status: "Active",
    thumbnail: "https://i.pinimg.com/originals/f9/ec/70/f9ec709b9cdd1d0afa28f58a30f1dfb8.gif",
    video:"",
  }

  fileChange(x,y,z){
    this.bento.imgur(x,y).then(a=>{
      console.log(a);
      eval(z+"=a")
    })
  }

  videos=[];

  safeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  inputvideo(x){
    this.bento.swal_input("Add Video","Insert Embeded Link", "https://youtube.com/embeded/url","text").then(a=>{
      console.log(this.product.photo[x])
      this.product.photo[x]['url_sanitized'] = this.safeUrl(a);
      this.product.photo[x]['url'] = <any>a;
    })
  }

  addphoto(){
    this.product.photo.push(
      {
        type:"photo",
        url:"https://media2.giphy.com/media/l1BgT72qhvqbQNGVO/source.gif",
        url_sanitized:null,
      }
    )
  }

  delphoto(x){
    if(this.lengthof(this.product.photo)>1){
      this.product.photo.splice(x,1);
    }
  }

  doReorder2(ev: any) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    
    this.product.photo = ev.detail.complete(this.product.photo);
  }


  ngOnInit() {

    firebase.database().ref('products_sort').on('value', data => {
      this.products_sort = data.val() || [];
    })

    firebase.database().ref('categories').once('value', data => {
      this.categories = data.val() || [];
    })

  }

  categories = [];

  products_sort = [];

  lengthof(x) {
    return (x ? x.length : 0);
  }

  create() {
    let buttons = {
      Cancel: {
        name: "Cancel",
        value: "Cancel",
      },
      Confirm: {
        name: "Confirm",
        value: "Confirm",
      },

    };

    this.bento.swal_button("Create Product", "confirm create?", "warning", buttons).then(a => {
      if (a == "Confirm") {
        if (this.product.sku != "" && this.product.name != "" && this.lengthof(this.product.categories) >= 0 && this.lengthof(this.product.photo) > 0 && this.product.thumbnail != "" && this.product.price > 0) {

          if (  ( this.products_sort ? this.products_sort.includes(this.product.sku) : false)==false){
            this.bento.swal_alert("Product Created!", "Created", "success", 2000);


            firebase.database().ref('products/' + this.product.sku).update({
              categories: this.product.categories,
              description: this.product.description || "",
              id: this.product.sku,
              sku: this.product.sku,
              name: this.product.name,
              photo: this.product.photo,
              price: this.product.price,
              price_slash: this.product.price_slash || 0,
              qty: this.product.qty || 0,
              ribbon: this.product.ribbon || "",
              sold: 0,
              status: "Active",
              thumbnail: this.product.thumbnail || "https://media2.giphy.com/media/l1BgT72qhvqbQNGVO/source.gif",
              video:this.product.video,
            }).then(()=>{
              this.products_sort.push(this.product.sku);
              firebase.database().ref('products_sort/').set(this.products_sort);
            })
  
  
            this.modal.dismiss();
          }else{
            this.bento.swal_alert("资料不足", "SKU duplicated", "error", 2000);
          }

            
        } else {
          this.bento.swal_alert("资料不足", "请再三检查资料已完成填写！", "error", 2000);
        }

      }
    })
  }

}
