import { Component, OnInit } from '@angular/core';
import { BentoService } from '../bento.service';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import * as firebase from 'firebase';

@Component({
  selector: 'app-editcategory',
  templateUrl: './editcategory.page.html',
  styleUrls: ['./editcategory.page.scss'],
})
export class EditcategoryPage implements OnInit {

  constructor(public bento:BentoService, public nav:NavController, public modal:ModalController, public navParams: NavParams) { }

  ngOnInit() {
    this.id = this.navParams.data.id
    firebase.database().ref('categories/').once('value',data=>{
      this.categories = data.val();
    })
  }
  id=0;
  categories=[]

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Categories", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        this.bento.swal_alert("Categories Created!","Created","success",2000);
        firebase.database().ref('categories/').set(this.categories);
        this.modal.dismiss();
      }
    })
  }
  name=""
  img="https://i.pinimg.com/originals/f9/ec/70/f9ec709b9cdd1d0afa28f58a30f1dfb8.gif";

  fileChange(x,y){
    this.bento.imgur(x,y).then(a=>{
      console.log(a);
      this.categories[this.id].photo=<any>a;
    })
  }

}
