import { Component, OnInit } from '@angular/core';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';
import { NavController, ModalController } from '@ionic/angular';
@Component({
  selector: 'app-createbroadcast',
  templateUrl: './createbroadcast.page.html',
  styleUrls: ['./createbroadcast.page.scss'],
})
export class CreatebroadcastPage implements OnInit {

  constructor(public bento:BentoService, public nav:NavController, public modal:ModalController) { }

  ngOnInit() {
    firebase.database().ref('broadcasts/').once('value',data=>{
      this.broadcast = data.val() || [];
    })
  }

  broadcast=[]

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Broadcast", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        this.bento.swal_alert("Broadcast Created!","Created","success",2000);
        this.broadcast.unshift({thumbnail:this.img});
        firebase.database().ref('broadcasts/').set(this.broadcast);
        this.modal.dismiss();
      }
    })
  }

  img="https://i.pinimg.com/originals/f9/ec/70/f9ec709b9cdd1d0afa28f58a30f1dfb8.gif";

  fileChange(x,y){
    this.bento.imgur(x,y).then(a=>{
      console.log(a);
      this.img=<any>a;
    })
  }

}
