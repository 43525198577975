import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, AlertController } from '@ionic/angular';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-editagent',
  templateUrl: './editagent.page.html',
  styleUrls: ['./editagent.page.scss'],
})
export class EditagentPage implements OnInit {

  constructor(public modal:ModalController,public bento:BentoService, public navParams:NavParams, public alertController:AlertController) { }

  agent={
    name:"",
    phone:"",
    email:"",
    // payouts:{},
    password:"",
  }
  fil(x){
    return (x ? Object.values(x).reverse() : [])
  }

  async addpay(){
    const alert = await this.alertController.create({
      header: 'Payout',
      inputs: [
        {
          name: 'amount',
          type: 'number',
          placeholder: 'Amount',
          min:0,
        },
        {
          name: 'remark',
          type: 'text',
          placeholder: 'Remark',
          min:0,
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        },
        {
          text: 'Confirm',
          handler: (a) => {
            console.log(a);
            if(a.amount>0){
              let keyer = firebase.database().ref('agents/'+this.id+'/payouts/').push({
                amount:this.bento.numbertodigit(a.amount,2),
                remark:a.remark || "",
                date:firebase.database.ServerValue.TIMESTAMP,
              }).key;

              (!this.agent['payouts'] ? this.agent['payouts']={} : true);
              this.agent['payouts'][keyer]={
                amount:this.bento.numbertodigit(a.amount,2),
                remark:a.remark || "",
                date:new Date().getTime(),
              }
            }
          }
        },
      ]
    });

    await alert.present();
  }


  postcodes="";

  ngOnInit() {
    this.id = this.navParams.data.id
    firebase.database().ref('agents/'+this.id).once('value',data=>{
      this.agent = data.val();
    })
  }
  id="";
  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Update Agent", "confirm update?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        if(this.agent.name!="" && this.agent.email !="" && this.agent.phone!="" && this.agent.password!=""){

          this.bento.swal_alert("Agent Update!","Created","success",2000);


          firebase.database().ref('agents/'+this.id).update({
            name:this.agent.name,
            phone:this.agent.phone,
            email:this.agent.email,
            password:this.agent.password,
          })
     

        this.modal.dismiss();
        }else{
          this.bento.swal_alert("资料不足","请再三检查资料已完成填写！","error",2000);
        }
      
      }
    })
  }

}
