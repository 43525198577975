import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-editbranch',
  templateUrl: './editbranch.page.html',
  styleUrls: ['./editbranch.page.scss'],
})
export class EditbranchPage implements OnInit {

  constructor(public modal:ModalController,public bento:BentoService, public navParams:NavParams) { }

  branch={
    name:"",
    latitude:3,
    longitude:101,
    postcodes:{},
    status:true,
    default:false,
  }

  postcodes="";


  branches=[];
  id="";

  ngOnInit() {
    this.id = this.navParams.data.id
    firebase.database().ref('branches').once('value',data=>{
      this.branches = Object.entries(data.val());
      this.branch = data.val()[this.id];

      this.postcodes = this.listout(this.branch['postcodes'])
    })

  }

  listout(x){
    return (x? Object.keys(x).reduce((a,b)=>a+", "+b,"") : "")
  }

  okcode(x){

    return new Promise((resolve, reject)=>{
      let holder = true;

      ((this.branches.filter(c=>c[0]!=this.id)).map(a=>a[1].postcodes)).forEach(element => {
        
        (Object.keys(element).includes(x) == true ? holder=false : null)

      });

      resolve(holder);

    })

  }


  converter(x){
    if(x){
      
      let holder={};

      ((((x.split(",").join("|")).split("\n").join("|")).split(" ").join("|")).split("|")).forEach(element => {
        if(element!=""){

          this.okcode(element).then(a=>{
            console.log(a);
            if(a==true){
              holder[element]=true;
            }
          })

          
        }
      });

      return holder;

    }else{
      return {};
    }
  }

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Updated", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        this.bento.swal_alert("Branch Updated!","Created","success",2000);
        this.branch.postcodes = this.converter(this.postcodes);
        setTimeout(() => {
          firebase.database().ref('branches/'+this.id).update(this.branch);
        }, 100);

        this.modal.dismiss();
      }
    })
  }

}
