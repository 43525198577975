import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditbranchPageRoutingModule } from './editbranch-routing.module';

import { EditbranchPage } from './editbranch.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditbranchPageRoutingModule
  ],
  declarations: [EditbranchPage]
})
export class EditbranchPageModule {}
