import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BentoService } from '../bento.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-createagent',
  templateUrl: './createagent.page.html',
  styleUrls: ['./createagent.page.scss'],
})
export class CreateagentPage implements OnInit {

  constructor(public modal:ModalController,public bento:BentoService) { }

  agent={
    name:"",
    phone:"",
    email:"",
    // payouts:{},
    password:"",
  }

  postcodes="";

  ngOnInit() {

  }

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Agent", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        if(this.agent.name!="" && this.agent.email !="" && this.agent.phone!="" && this.agent.password!=""){

          this.bento.swal_alert("Agent Created!","Created","success",2000);


          firebase.database().ref('agents').push(this.agent)
     

        this.modal.dismiss();
        }else{
          this.bento.swal_alert("资料不足","请再三检查资料已完成填写！","error",2000);
        }
      
      }
    })
  }

}
