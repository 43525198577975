import { Component, OnInit } from '@angular/core';
import { BentoService } from '../bento.service';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import * as firebase from 'firebase';

@Component({
  selector: 'app-editbroadcast',
  templateUrl: './editbroadcast.page.html',
  styleUrls: ['./editbroadcast.page.scss'],
})
export class EditbroadcastPage implements OnInit {


  constructor(public bento:BentoService, public nav:NavController, public modal:ModalController, public navParams:NavParams) { }



  ngOnInit() {

    this.id = this.navParams.data.id
    console.log(this.id)
    firebase.database().ref('broadcasts/').once('value',data=>{
      this.broadcast = data.val();
    })
  }

  id=0;
  broadcast=[]

  create(){
    let buttons={
      Cancel:{
        name:"Cancel",
        value:"Cancel",
      },
      Confirm:{
        name:"Confirm",
        value:"Confirm",
      },
      
    };

    this.bento.swal_button("Create Broadcast", "confirm create?", "warning" , buttons).then(a=>{
      if(a=="Confirm"){
        this.bento.swal_alert("Broadcast Created!","Created","success",2000);
        firebase.database().ref('broadcasts/').set(this.broadcast);
        this.modal.dismiss();
      }
    })
  }

  fileChange(x,y){
    this.bento.imgur(x,y).then(a=>{
      console.log(a);
      this.broadcast[this.id].thumbnail=<any>a;
    })
  }

}
