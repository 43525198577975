import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CreateagentPageRoutingModule } from './createagent-routing.module';

import { CreateagentPage } from './createagent.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CreateagentPageRoutingModule
  ],
  declarations: [CreateagentPage]
})
export class CreateagentPageModule {}
